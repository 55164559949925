// Importa i moduli Firebase necessari
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import { STATI_ASTA } from "../utils/Utils";

// Configurazione Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Inizializza l'app Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Riferimento al database Firestore
const db = firebaseApp.firestore();

// Riferimento all'autenticazione Firebase
const auth = firebase.auth();

// Inizializza Firebase Analytics
firebaseApp.analytics();

// Esporta db, auth e firebase per utilizzo in altre parti dell'app
export { db, auth, firebase };


// Metodo per ottenere offerte di una specifica inserzione
// -----------------------------------------------------------------
export const getBusteAsta = (idInserzioneEspVendita) =>
  new Promise((resolve, reject) =>
    db
      .collection("Offerte")
      .where("idInserzioneEspVendita", "==", idInserzioneEspVendita)
      .get()
      .then((querySnap) =>
        resolve(
          querySnap.docs.map((docRef) => ({
            idRef: docRef.id,
            ...docRef.data(),
          }))
        )
      )
      .catch((error) => reject(error))
  );


// Metodo per validare un'offerta
// -----------------------------------------------------------------
export const firebaseValidaOfferta = (docId, value) =>
  new Promise((resolve, reject) =>
    db
      .collection("Offerte")
      .doc(docId)
      .update({ offertaValida: value }, { merge: true })
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per aggiornare lo stato di versamento della cauzione
// -----------------------------------------------------------------
export const updateVersaCauzione = (docId, value) =>
  new Promise((resolve, reject) =>
    db
      .collection("Offerte")
      .doc(docId)
      .update({ cauzioneVersata: value }, { merge: true })
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per cambiare lo stato di un'aula virtuale
// -----------------------------------------------------------------
export const changeStateAulaVirtuale = (value, messageId) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(messageId)
      .update({ aulaVirtuale: value }, { merge: true })
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per aggiornare il log di una inserzione
// -----------------------------------------------------------------
export const updateLogInserzione = (stringa, idInserzione) =>
  new Promise((resolve, reject) =>
    db
      .collection("LogInserzioni")
      .doc(idInserzione)
      .set(
        { logs: firebase.firestore.FieldValue.arrayUnion(stringa) },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per aggiornare l'avvio della gara
// -----------------------------------------------------------------
export const updateAvvioGara = (idInserzione, data) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update(
        {
          ...data,
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// -----------------------------------------------------------------
/* Metodi per aggiornare i rilanci dell'asta, sia sincrona che asincrona */

export const updateRilanciAstaSincrona = (
  idInserzione,
  miglioreOfferta,
  rilanci,
  dataFineVendita,
  miglioreOfferente
) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update(
        {
          miglioreOfferta: miglioreOfferta,
          rilanci: rilanci,
          dataFineVenditaReale: dataFineVendita,
          miglioreOfferente,
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );

//  export const updateRilanciAstaAsincronaNormal = (
export const updateRilanciAstaAsincrona = (
  idInserzione,
  miglioreOfferta,
  rilanci,
  miglioreOfferente,
  offertaMinima
) => {
    const prezzoBase = miglioreOfferta || offertaMinima;

    new Promise((resolve, reject) =>
      db
        .collection("Inserzioni")
        .doc(idInserzione)
        .set(
          {
            miglioreOfferta: prezzoBase,
            rilanci: rilanci,
            miglioreOfferente: miglioreOfferente,
          },
          { merge: true }
        )
        .then(() => resolve())
        .catch((error) => reject(error))
    );
  }


  // ----------------------------------------------------------------------------------------------------
  // quello che varia rispetto alla normal è la data di fine vendita dinamica, in tal caso implementata, 
  // e la gestione   // del numero massimo di rilanci
  /* export const updateRilanciAstaAsincronaNotNormal = (
    idInserzione,
    miglioreOfferta,
    rilanci,
    dataFineVendita,
    miglioreOfferente
  ) =>
    new Promise((resolve, reject) =>
      db
        .collection("Inserzioni")
        .doc(idInserzione)
        .set(
          {
            miglioreOfferta: miglioreOfferta,
            rilanci: rilanci,
            dataFineVenditaReale: dataFineVendita,
            miglioreOfferente,
            maxRilanci: firebase.firestore.FieldValue.increment(1),
          },
          { merge: true }
        )
        .then(() => resolve())
        .catch((error) => reject(error))
    );
 */


// Metodo per aggiornare il log delle offerte dei partecipanti
// -----------------------------------------------------------------
export const updateLogOffertePartecipante = (idInserzione, stringa) =>
  new Promise((resolve, reject) =>
    db
      .collection("LogOffertePartecipanti")
      .doc(idInserzione)
      .set(
        { logs: firebase.firestore.FieldValue.arrayUnion(stringa) },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per aggiornare il log delle offerte dei referenti
// -----------------------------------------------------------------
export const updateLogOfferteReferenti = (idInserzione, stringa) =>
  new Promise((resolve, reject) =>
    db
      .collection("LogOfferteReferenti")
      .doc(idInserzione)
      .set(
        { logs: firebase.firestore.FieldValue.arrayUnion(stringa) },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per estromettere un partecipante
// -----------------------------------------------------------------
export const updateEstromettiPartecipante = (idRef) =>
  new Promise((resolve, reject) =>
    db
      .collection("Offerte")
      .doc(idRef)
      .update(
        {
          offertaValida: false,
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per terminare l'asta con un unico offerente
// -----------------------------------------------------------------
export const terminaAstaUnicoOfferente = (
  idInserzione,
  miglioreOfferta,
  miglioreOfferente
) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update(
        {
          miglioreOfferente,
          miglioreOfferta,
          vincitore: miglioreOfferente,
          statoAsta: STATI_ASTA.TERMINATA_NO_INVIO,
          esitoAsta: "AGGIUDICATA",
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per terminare un'asta deserta
// -----------------------------------------------------------------
export const terminaAstaDeserta = (idInserzione) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update({
        esitoAsta: "DESERTA",
        statoAsta: STATI_ASTA.TERMINATA_NO_INVIO,
      })
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per sospendere un'asta
// -----------------------------------------------------------------
export const sospendiAsta = (idInserzione) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update({
        esitoAsta: "SOSPESA",
        statoAsta: STATI_ASTA.TERMINATA_OK_INVIO,
      })
      .then(() => resolve())
      .catch((error) => reject(error))
  );



// Metodo per terminare l'asta con il miglior offerente
// -----------------------------------------------------------------
export const terminaAstaMigliorOfferente = (
  idInserzione,
  miglioreOfferente,
  miglioreOfferta
) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update({
        esitoAsta: "AGGIUDICATA",
        vincitore: miglioreOfferente,
        vincitoreOfferta: miglioreOfferta,
        statoAsta: STATI_ASTA.TERMINATA_NO_INVIO,
      })
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// -----------------------------------------------------------------
/**Il metodo terminaAstaAltroOfferente sembra essere progettato per gestire 
 * la conclusione di un'asta, specificamente in un caso dove l'offerente vincente 
 * non è quello che ha fatto l'ultima offerta più alta. */
export const terminaAstaAltroOfferente = (
  idInserzione,
  vincitore,
  importoOfferta
) =>
  new Promise((resolve, reject) =>
    db
      .collection("Inserzioni")
      .doc(idInserzione)
      .update({
        esitoAsta: "AGGIUDICATA",
        vincitore: vincitore,
        vincitoreOfferta: importoOfferta,
        statoAsta: STATI_ASTA.TERMINATA_NO_INVIO,
      })
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per aggiungere una busta cartacea
// -----------------------------------------------------------------
export const addBustaCartacea = (bustaCartacea) =>
  new Promise((resolve, reject) =>
    db
      .collection("Offerte")
      .add({ ...bustaCartacea })
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per aggiungere un elemento alla chat
// -----------------------------------------------------------------
export const addChatItem = (idInserzione, text) =>
  new Promise((resolve, reject) =>
    db
      .collection("ChatInserzioni")
      .doc(idInserzione)
      .set(
        {
          chatItems: firebase.firestore.FieldValue.arrayUnion(text),
        },
        { merge: true }
      )
      .then(() => resolve())
      .catch((error) => reject(error))
  );


// Metodo per ottenere lo stato dell'applicazione
// -----------------------------------------------------------------
export const getStatusApp = () =>
  new Promise((resolve, reject) =>
    db
      .collection("ManutenzioneProgrammate")
      .doc(process.env.REACT_APP_STATUS_APP_DOCUMENT_ID)
      .get()
      .then((docRef) => resolve(docRef.data()))
      .catch((error) => reject(error))
  );



export const getPartecipantsEmails = (idInserzione) => {

}


export const getPartecipantsPhoneNumbers = (idInserzione) => {
  
}



// Metodo per ottenere gli allegati di una specifica asta
// -----------------------------------------------------------------
export const getAllegatiAsta = (idAsta) =>
  new Promise((resolve, reject) =>
    db
      .collection("Allegati")
      .where("id_asta", "==", idAsta)
      .get()
      .then((querySnapshot) =>
        resolve(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        )
      )
      .catch((error) => reject(error))
  );
