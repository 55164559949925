import React from "react";
import { Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function InfoMessage() {
  return (
    <Alert
      icon={<InfoIcon fontSize="inherit" />}
      severity="info"
      sx={{
        maxWidth: 600,
        margin: "20px auto",
        paddingX: "16px", // Padding solo a destra e sinistra
        borderRadius: "8px", // Opzionale: arrotonda gli angoli
      }}
    >
      Se vi saranno offerte negli ultimi 15 minuti, il tempo rimanente sarà reinizializzato a 15 minuti. Potrebbero essere necessari alcuni secondi per sincronizzarsi con il timer corretto.
    </Alert>
  );
}

export default InfoMessage;
