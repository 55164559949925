/*
Il componente GaraPanel gestisce l'interfaccia per un partecipante in un'asta. 
Include un conto alla rovescia, informazioni sulla gara corrente, un form per 
effettuare offerte e una cronologia delle offerte precedenti. 
Utilizza varie funzioni per aggiornare le offerte e i log relativi al partecipante e al referente. 
Mostra inoltre un modal di conferma prima di inviare un'offerta. 
*/



import React from "react";
import { useState, useCallback } from "react";

import InfoMessage from "../../InfoMessage";

//components
import { Grid, TextField, Button } from "@mui/material";
import CustomModal from "../../../utils/CustomModal/CustomModal";
import CountDown from "../../../utils/CountDown";
import CronologiaOffertePartecipante from "./CronologiaOffertePartecipante";

//hooks
import { useAsta } from "../../../context/useAsta";

//utils
import {
  updateRilanciAstaSincrona,
  updateLogOffertePartecipante,
  updateLogOfferteReferenti,
  updateRilanciAstaAsincrona,
} from "../../../services/firebase";
import moment from "moment";
import NumberFormat from "react-number-format";
import { convertDateInIta, getDateAstalex } from "../../../utils/Utils";
import "./PartecipantePanel.css";
import accounting from "accounting";
import { toast } from "react-toastify";

import { sendNotifications } from "../../../services/sendInfoRilanci"

import { catchUltimaOfferta } from "../../../services/timerServices"

const GaraPanel = () => {
  // Hook per accedere allo stato dell'asta e impostare il rilancio e il modal

  // @test verificare se serve ontimeleft
  const { asta, tempoRimanente, aulaVirtuale } = useAsta();
  const [rilancio, setRilancio] = useState("");
  const [modalRilancio, setModalRilancio] = useState(false);

  // Funzione per chiudere il modal di conferma
  const closeModalHandler = useCallback(() => {
    setModalRilancio(false);
  }, []);


  // Gestisce la sottomissione del form di rilancio
  // @todo verificare all'inizio, quando la migliore offerta non ci sta
  const submitHandler = (e) => {
    e.preventDefault();
  
    // Funzione per convertire stringhe in numeri correttamente formattati
    const convertToNumber = (value) => {
      if (!value) return 0; // Gestione dei valori null o undefined
      // Rimuove solo i punti che separano le migliaia e sostituisce le virgole con punti per i decimali
      return parseFloat(value.replace(/(\.\d{2})$|(?<=\d)\.(?=\d{3})/g, match => match.includes('.') ? '' : match).replace(",", "."));
    };
  
    // Applica la conversione ai valori
    const currentBestOffer = convertToNumber(asta.miglioreOfferta || asta.datiVendita.offertaMinima);
    const rialzoMinimo = convertToNumber(asta.datiVendita.rialzoMinimo);
    const offertaRilancio = convertToNumber(rilancio);
  
    console.log("rilancio: " + offertaRilancio);
    console.log("currentBestOffer: " + currentBestOffer);
    console.log("asta.datiVendita.rialzoMinimo: " + rialzoMinimo);
    console.log("asta.datiVendita.offertaMinima: " + asta.datiVendita.offertaMinima);
  
    // Verifica se l'importo di rilancio è inferiore alla somma di rialzoMinimo e currentBestOffer
    if (offertaRilancio < rialzoMinimo + currentBestOffer) {
      toast.error("Attenzione, l'offerta è troppo bassa");
      return; // Blocca la chiamata successiva
    }
  
    // Se la verifica è superata, apri il modal per confermare l'offerta
    setModalRilancio(true);
  };








  // @todoprod rilancio - mandare pec e sms a tutti
  // Funzioni per gestire il rilancio in aste asincrone 
  const rilancioHandlerAsincrona = async () => {
    /****************************CHECK SE IL TEMPO E' SCADUTO ********************************************/
    if (tempoRimanente <= 1000) 
      return toast.error("Attenzione il tempo è scaduto!");
  
    try {
      const response = await getDateAstalex();
  
      // Verifica se asta.rilanci esiste, se no, inizializzalo come array vuoto
      const rilanciCorrenti = Array.isArray(asta.rilanci) ? asta.rilanci : [];
  
      await catchUltimaOfferta(asta.idInserzioneEspVendita, moment().valueOf())
  
      const newRilanci = [
        ...rilanciCorrenti,
        {
          rilancio,
          offerente: aulaVirtuale.nomeUtente,
          dataOfferta: response.data.date,
        },
      ];
  
      await updateRilanciAstaAsincrona(
        asta.idInserzioneEspVendita,
        rilancio,
        newRilanci,
        aulaVirtuale.nomeUtente,
        asta.datiVendita.offertaMinima
      );
  
      const stringaOffertaPartecipante = `L'offerente ${aulaVirtuale.nomeUtente} ha offerto ${accounting.formatMoney(rilancio, "€")}  in data  ${convertDateInIta(response.data.date)}`;
  
      await updateLogOffertePartecipante(asta.idInserzioneEspVendita, stringaOffertaPartecipante);
  
      const stringaOffertaReferente = `L'offerente ${aulaVirtuale.nomeReale}(${aulaVirtuale.nomeUtente})  ha offerto ${accounting.formatMoney(rilancio, "€")}  in data  ${convertDateInIta(response.data.date)}`;
  
      await updateLogOfferteReferenti(asta.idInserzioneEspVendita, stringaOffertaReferente);
  
      toast.success("Complimenti hai rilanciato per questa asta!");
  
      setModalRilancio(false);
  
      sendNotifications(asta.idInserzioneEspVendita, rilancio);
    } 
    
    catch (error) {
      toast.error("Si è verificato un errore");
      console.log(error);
    }
  };



  // @todoprod rilancio - mandare pec e sms a tutti
  // Funzioni per gestire il rilancio in aste sincrone
   const rilancioHandlerSincrona = async () => {
    /* if (
      asta.datiVendita.modalitaVendita ===
        "SINCRONA MISTA" ||
      asta.datiVendita.modalitaVendita ===
        "SINCRONA TELEMATICA"
    ) {
      //Check se il controllo è scaduto
      if (tempoRimanente <= 0)
        return toast.error("Attenzione il tempo è scaduto!");



      const response = await getDateAstalex();

      const newRilanci = [
        ...asta.rilanci,
        {
          rilancio,
          offerente: aulaVirtuale.nomeUtente,
          dataOfferta: response.data.date,
        },
      ];

      await updateRilanciAstaSincrona(
        asta.idInserzioneEspVendita,
        rilancio,
        newRilanci,
        newDate,
        aulaVirtuale.nomeUtente
      );

      const stringaOffertaPartecipante = `L'offerente ${
        aulaVirtuale.nomeUtente
      } ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;
      await updateLogOffertePartecipante(
        asta.idInserzioneEspVendita,
        stringaOffertaPartecipante
      );

      const stringaOffertaReferente = `L'offerente ${aulaVirtuale.nomeReale}(${
        aulaVirtuale.nomeUtente
      })  ha offerto ${accounting.formatMoney(
        rilancio,
        "€"
      )}  in data  ${convertDateInIta(response.data.date)}`;

      await updateLogOfferteReferenti(
        asta.idInserzioneEspVendita,
        stringaOffertaReferente
      );

      toast.success("Complimenti hai rilanciato per questa asta!");

      sendNotifications(asta.idInserzioneEspVendita, rilancio) 

    }*/

    setModalRilancio(false);
  }; 




  // Rendering del componente
  return (
    <>
      <div className="gara_container">
        <div className="gara_panel_title"> GARA IN CORSO </div>

        <div className="gara_box_info">
          <Grid container>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                INIZIO:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {asta.datiVendita.modalitaVendita ===
                  "ASINCRONA TELEMATICA"
                    ? convertDateInIta(asta.datiVendita.dataOraVendita)
                    : convertDateInIta(asta.dataFineVendita)}
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                FINE:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {" "}
                  {convertDateInIta(asta.dataFineVenditaReale)}
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                TRIBUNALE:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria.tribunale &&
                    asta.datiProcedura.proceduraGiudiziaria.tribunale
                  }
                </div>
              </div>
            </Grid>
            <Grid item padding={2} md={6} xs={12}>
              <div>
                RITO:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {
                    asta.datiProcedura.proceduraGiudiziaria.rito
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          {/************  INFO OFFERTE  **********/}
          <Grid item md={6} xs={12}>
          <InfoMessage />
            <div className="gara_text_info">
              Tempo rimanente:{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                <CountDown />
              </div>
            </div>

            <div className="gara_text_info">
              {" "}
              Migliore offerta [€]:{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {" "}
                {asta.miglioreOfferta}
              </div>
            </div>
            <div className="gara_text_info">
              {" "}
              Rialzo minimo [€]:{" "}
              <div style={{ fontWeight: 600, display: "inline" }}>
                {" "}
                {/* {accounting.formatMoney(asta.datiVendita.rialzoMinimo, "€")} */}
                {asta.datiVendita.rialzoMinimo}
              </div>
            </div>


           

{/*             {asta.datiVendita.modalitaVendita ===
              "ASINCRONA TELEMATICA" && (
              <div className="gara_text_info">
                {" "}
                Numero di rilanci effettuati:{" "}
                <div style={{ fontWeight: 600, display: "inline" }}>
                  {asta.maxRilanci}
                </div>
              </div>
            )} */}

            <form className="gara_form_container" onSubmit={submitHandler}>
              {tempoRimanente >= 1000 ? (
                <>
                  <NumberFormat
                    value={rilancio}
                    name={"rilancio"}
                    customInput={TextField}
                    type="text"
                    prefix={"€"}
                    thousandSeparator={false}
                    label="Rilancio"
                    onValueChange={({ value: v }) => setRilancio(v)}
                  />

                  
                  <Button type="submit" variant="contained">
                    Offri
                  </Button>
                </>
              ) : (
                <div style={{ color: "red" }}>
                  Attenzione il tempo è scaduto!
                </div>
              )}
            </form>
          </Grid>

          {/************  CRONOLOGIA OFFERTE  *************/}
          <Grid item md={6} xs={12}>
            <CronologiaOffertePartecipante />
          </Grid>
        </Grid>
      </div>

      
      <CustomModal
        title={
          "Sei sicuro di voler offrire " + accounting.formatMoney(rilancio, "€")
        }
        content="Attenzione, una volta confermato non potrai più annullare l'operazione."
        openModal={modalRilancio}
        handleClose={closeModalHandler}
        submitModal={
          asta.datiVendita.modalitaVendita ===
          "ASINCRONA TELEMATICA"
            ? rilancioHandlerAsincrona
            : rilancioHandlerSincrona
        }
        color={"green"}
      />
      


    </>
  );
};

export default GaraPanel;
