/*
Il componente DatiPrincipali in React serve a visualizzare le informazioni principali relative a un'asta specifica. 
Utilizza il hook useAsta per accedere ai dati dell'asta corrente. 
Il componente presenta una serie di dati chiave come la data e l'ora della vendita, 
la modalità e la tipologia di vendita, il rialzo minimo, il termine per la presentazione delle offerte, 
il prezzo base e dettagli sulla procedura dell'asta, come il tipo e l'ID della procedura e l'ID dell'inserzione.
*/



import React from "react";

//hooks
import { useAsta } from "../../context/useAsta";

//components
import { Grid } from "@mui/material";

//utils
import { convertDateInIta } from "../../utils/Utils";
import "../../pages/Asta/Asta.css";

const DatiPrincipali = () => {
  const { asta } = useAsta();

  const { inserzioneEspVendita } = asta;


  if (asta && 
        asta.datiVendita && 
        asta.datiVendita.modalitaVendita && 
        asta.datiVendita.tipologiaVendita && 
        asta.datiProcedura.tipoProcedura
        ) {
    return (
      <div className="dati_principali_container">
        <div className="dati_principali_title"> Informazioni Rilevanti</div>

        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Data Ora Vendita
          </Grid>
          <Grid item md={6} xs={6}>
            {convertDateInIta(asta.datiVendita.dataOraVendita)}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Modalità Vendita
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiVendita.modalitaVendita.toLowerCase()}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Tipologia Vendita
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiVendita.tipologiaVendita.toLowerCase()}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Rialzo Minimo
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiVendita.rialzoMinimo + "€"}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Termine Presentazione Offerte
          </Grid>
          <Grid item md={6} xs={6}>
            {convertDateInIta(
              asta.datiVendita.terminePresentazioneOfferte
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Prezzo Base
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiVendita.prezzoValoreBase + "€"}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7}>
          <Grid item md={6} xs={6}>
            Offerta Minima 
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiVendita.offertaMinima + "€"}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7} >
          <Grid item md={6} xs={6}>
            Tipo Procedura
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiProcedura.tipoProcedura}
          </Grid>
        </Grid>
        <hr />

        <Grid container spacing={2} paddingX={1} paddingY={0.7} >
          <Grid item md={6} xs={6}>
            Id Procedura
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.datiProcedura.idProcedura}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} paddingX={1} paddingY={0.7} >
          <Grid item md={6} xs={6}>
            Id Inserzione
          </Grid>
          <Grid item md={6} xs={6}>
            {asta.idInserzioneEspVendita}
          </Grid>
        </Grid>

      </div>
    );
  }

  return null;
};

export default DatiPrincipali;
