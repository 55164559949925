/*
Il componente TableDocumentiAnonimi visualizza e gestisce una tabella di documenti anonimi relativi alle offerte di un'asta. 
Utilizza i dati delle buste filtrando quelle telematiche e offre la possibilità di scaricare i documenti anonimi associati a ciascuna busta. 
L'interfaccia permette di espandere o comprimere la visualizzazione della tabella e include un'icona per il download dei documenti. 
La decodifica e il download del documento vengono gestiti tramite la funzione downloadDocumentoAnonimo, che converte il file codificato 
in base64 e lo rende scaricabile.
*/

import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/storage";
import axios from "axios";

//@mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useAsta } from "../../../context/useAsta";

//@mui
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

//utils
import { tableDocumentiAnonimiHead } from "../../../utils/SearchData";
import { motion } from "framer-motion";
import atob from "atob";
import FileDownload from "js-file-download";

const TableDocumentiAnonimi = () => {
    
  const { buste } = useAsta();
  const [isOpen, setOpen] = useState(false);


  const busteTelematiche = buste &&  buste.filter((busta)=>busta.isTelematica)

  /* const downloadDocumentoAnonimo = (busta) => {
    const fileName = "documentoAnonimo.pdf";
    const { offertaRequest } = busta;
    //La funzione atob decodifica il file base64, in una nuova stringa ,con un carattere per ogni byte
    //in base ai dati ricevuti.
    const byteCharacters = atob(offertaRequest.docAnonimo);
    
    const byteNumbers = new Array(byteCharacters.length);

    //creiamo un array di byte, andando a scrorrere la stringa !
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    var bb = new Blob([byteArray], {
      type: "application/pdf;base64",
    });

    FileDownload(bb, fileName);
  }; */

  const downloadDocumentoAnonimo = async (busta) => {

    //  const downloadFile = async (urlOrPath, fileName) => {


      try {
      const fileName = "documentoAnonimo.pdf";
      const { offertaRequest } = busta;

      if (!offertaRequest || !offertaRequest.docAnonimo) {
        console.error("Il documento anonimo non è disponibile.");
        return;
      }

      // Decodifica Base64 in una stringa binaria

      const base64DataUrl = offertaRequest.docAnonimo;
      const storageRef = base64DataUrl.startsWith("https://")
        ? firebase.storage().refFromURL(base64DataUrl)
        : firebase.storage().ref(base64DataUrl);

        const downloadURL = await storageRef.getDownloadURL();

         // Scarica i dati dal database
      const response = await axios.get(downloadURL);
  
      // I dati sono memorizzati in Base64 nel DB
      const base64Data = response.data;

  
      // Decodifica la stringa base64 in un array di byte
      const byteCharacters = atob(base64Data);
      const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
  
      // Crea un oggetto Blob dai dati decodificati
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      // Usa il blob per scaricare il file
      FileDownload(blob, `${fileName}`);
  
    } catch (error) {
      console.error("Errore durante il download del file:", error);
    }
  };

  const downloadDocumentoAnonimoOld = (busta) => {
    const fileName = "documentoAnonimo.pdf";
    const { offertaRequest } = busta;
  
    if (!offertaRequest || !offertaRequest.docAnonimo) {
      console.error("Il documento anonimo non è disponibile.");
      return;
    }
  
    // Decodifica Base64 in una stringa binaria
    const byteCharacters = atob(offertaRequest.docAnonimo);
  
    // Crea un array di byte dai caratteri decodificati
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    // Converte l'array di byte in un Uint8Array
    const byteArray = new Uint8Array(byteNumbers);
  
    // Crea un Blob per il download
    const blob = new Blob([byteArray], { type: "application/pdf" });
  
    // Usa FileDownload per scaricare il file
    FileDownload(blob, fileName);
  };
  

  return (
    <React.Fragment>
      <div className="partecipante_dropdown_container">
        {!isOpen ? (
          <AddBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        ) : (
          <IndeterminateCheckBoxIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        )}
        <div style={{ fontWeight: 500 }}>Documenti Anonimi</div>
      </div>
      {isOpen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
        >
          <TableContainer className="partecipante_table_container active">
            <Table sx={{ minWidth: 650 }} className="referente_table">
              <TableHead>
                <TableRow>
                  {tableDocumentiAnonimiHead.map((cell, index) => {
                    return (
                      <TableCell align="center" key={index}>
                        {cell.title}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {busteTelematiche  && busteTelematiche.map((busta, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div style={{ textAlign: "center" }}>
                        {busta.credenziali.username}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="partecipante_documento_anonimo">
                        <div
                          className="partecipante_icon"
                          onClick={() => {
                            downloadDocumentoAnonimo(busta);
                          }}
                        >
                          <FileDownloadIcon />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default TableDocumentiAnonimi;
