import React, { memo } from "react";
import {
  Modal,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FileDownload from "js-file-download";
import X2JS from "x2js";

import firebase from "firebase/app";
import "firebase/storage";
import axios from "axios";



const ModalDatiBusta = memo(({ open, onClose, busta }) => {
  const { offertaRequest } = busta;

  const docAnonimoUrl = offertaRequest.docAnonimo
  const pecOffertaUrl = offertaRequest.pecOfferta
  const bolloDigitaleUrl = offertaRequest.bolloDigitale

  const downloadFile = async (urlOrPath, fileName) => {
    try {
      // Usa `refFromURL` se l'argomento è un URL completo, altrimenti `ref` per percorso relativo
      const storageRef = urlOrPath.startsWith("https://")
        ? firebase.storage().refFromURL(urlOrPath)
        : firebase.storage().ref(urlOrPath);
  
      const downloadURL = await storageRef.getDownloadURL();
  
      // Scarica i dati dal database
      const response = await axios.get(downloadURL);
  
      // I dati sono memorizzati in Base64 nel DB
      const base64Data = response.data;
  
      // Decodifica la stringa base64 in un array di byte
      const byteCharacters = atob(base64Data);
      const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
  
      // Crea un oggetto Blob dai dati decodificati
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      // Usa il blob per scaricare il file
      FileDownload(blob, `${fileName}`);
  
    } catch (error) {
      console.error("Errore durante il download del file:", error);
    }
  };
  

  const downloadXML = async () => {
    const x2js = new X2JS();
    const new_xml = x2js.js2xml(offertaRequest.offIntegrale);
    const xmltext = `<xml>${new_xml}</xml>`;
    const fileName = "OffertaIntegrale.xml";
    const bb = new Blob([xmltext], { type: "text/plain" });
    FileDownload(bb, fileName);
  };


  /*   
  const downloadDocumentoAnonimo = async () => {
    const fileName = "documentoAnonimo.pdf";
    const url = docAnonimoUrl; // Supponendo che tu abbia già l'URL
    await downloadFile(url, fileName);
  }; 
  */

  

  const downloadBolloDigitale = async () => {
    const x2js = new X2JS();
    const new_xml = x2js.js2xml(offertaRequest.bolloDigitale);
    const xmltext = `<xml>${new_xml}</xml>`;
    const fileName = "BolloDigitale.xml";
    const bb = new Blob([xmltext], { type: "text/plain" });
    FileDownload(bb, fileName);
  };

  const downloadDocumentoAnonimo = async () => {
    const fileName = "documentoAnonimo.pdf";
    const storagePath = docAnonimoUrl; // Percorso del file in Firebase Storage, ad esempio "folder/docAnonimo.pdf"
    await downloadFile(storagePath, fileName);
  };
  
  const downloadPecOfferta = async () => {
    const fileName = "pecOfferta.eml";
    const storagePath = pecOffertaUrl; // Percorso del file in Firebase Storage
    await downloadFile(storagePath, fileName);
  };
  
  const downloadAllegato = async (allegato) => {
    const fileName = `${allegato.nome}.${allegato.estensione}`;
    const urlOrPath = allegato.contenuto;
  
    if (!urlOrPath) {
      console.error("Contenuto non definito per l'allegato");
      return;
    }
  
    try {
      await downloadFile(urlOrPath, fileName);
    } catch (error) {
      console.error("Errore durante il download dell'allegato:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal_dati_anagrafici">
        <div className="modal_assisti_title">Contenuto Busta</div>

        {/**FILE*/}
        <Accordion className="modal_dati_accordition">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div style={{ fontWeight: 600, color: "white" }}>FILE</div>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
            className="modal_dati_accordition_details"
          >
            <Grid container padding={2} spacing={1}>
              <Grid item xs={12}>
                <div onClick={downloadXML} className="text_title_dati">
                  Offerta Integrale (XML)
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="text_title_dati"
                  onClick={downloadDocumentoAnonimo}
                >
                  Documento Anonimo
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="text_title_dati" onClick={downloadPecOfferta}>
                  Pec Offerta
                </div>
              </Grid>
              {offertaRequest.bolloDigitale && (
                <Grid item xs={12}>
                  <div
                    className="text_title_dati"
                    onClick={downloadBolloDigitale}
                  >
                    Bollo Digitale
                  </div>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion className="modal_dati_accordition">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div style={{ fontWeight: 600, color: "white" }}>ALLEGATI</div>
          </AccordionSummary>

          {offertaRequest.allegati.map((allegato) => (
            <AccordionDetails
              key={allegato.idAllegato}
              style={{ backgroundColor: "white", borderRadius: "0.5rem" }}
              className="modal_dati_accordition_details"
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  Id allegato:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.idAllegato}
                </Grid>
                <Grid item xs={6}>
                  Allegato per:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.allegatoPer}
                </Grid>
                <Grid item xs={6}>
                  Categoria:
                </Grid>
                <Grid item xs={6} fontWeight={600}>
                  {allegato.categoria}
                </Grid>
                <Grid item xs={6}>
                  Contenuto:
                </Grid>
                <Grid item xs={6}>
                  <div className="text_title_dati" onClick={() => { downloadAllegato(allegato) }}>
                    {`${allegato.nome}.${allegato.estensione}`}
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
        </Accordion>
      </Box>
    </Modal>
  );
});

export default ModalDatiBusta;
