import React, { memo } from "react";
import { useState, useEffect } from "react";

//@mui
import { Modal, Box, Grid, TextField, Button, FormGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

//hooks
import { useFormik } from "formik";
import { useAsta } from "../../../../context/useAsta";

//utils
import "./PannelloControllo.css";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import moment from "moment";
import accounting from "accounting";
import { toast } from "react-toastify";
import { updateAvvioGara } from "../../../../services/firebase";

const ModalTerminaEsameOfferte = memo(({ isOpen, handleClose }) => {
  const { asta, buste } = useAsta();

  const [miglioreOfferta, setMiglioreOfferta] = useState({
    offerente: "",
    value: 0,
  });

  //ASINCRONA TELEMATICA

  const initialValues = {
    rilancioMinimo:
      accounting.unformat(
        asta.datiVendita.rialzoMinimo,
        ","
      ) || "",
    timer:
      asta.datiVendita.modalitaVendita ===
      "ASINCRONA TELEMATICA"
        ? 15
        : 1,
    dataInizioVendita: "",
    orarioInizioVendita: "",
    dataFineVendita: "",
    orarioFineVendita: "",
  };

  const validationSchema = Yup.object({
    rilancioMinimo: Yup.string().required("Inserisci il rilancio minimo"),
    timer: Yup.number().required("Inserisci Timer"),
    dataInizioVendita: Yup.string().required("Inserisci data inizio vendita"),
    orarioInizioVendita: Yup.string().required("Inserisci ora inizio vendita"),
    dataFineVendita:
      asta.datiVendita.modalitaVendita ===
      "ASINCRONA TELEMATICA"
        ? Yup.string().required("Inserisci data fine vendita")
        : Yup.string(),
    orarioFineVendita:
      asta.datiVendita.modalitaVendita ===
      "ASINCRONA TELEMATICA"
        ? Yup.string().required("Inserisci ora fine vendita")
        : Yup.string(),
  });

  const handleSubmit = async (values) => {
    if (
      asta.datiVendita.modalitaVendita !==
      "ASINCRONA TELEMATICA"
    ) {
      const dateInizioVenditaOnly = moment(values.dataInizioVendita).format();
      const newDate = moment(dateInizioVenditaOnly)
        .add(values.orarioInizioVendita)
        .format();

      const data = {
        dataFineVendita: newDate,
        dataFineVenditaReale: newDate,
        rilancioMinimo: values.rilancioMinimo,
        timer: values.timer,
        miglioreOfferta: String(accounting.unformat(miglioreOfferta.value)),
        miglioreOfferente: miglioreOfferta.offerente,
        rilanci: [],
        dataInizioVendita:newDate
      };

      await updateAvvioGara(
        asta.idInserzioneEspVendita,
        data
      );

      toast.success("La gara inizierà tra poco!");
      handleClose();
    } else {
      const dateInizioVenditaOnly = moment(values.dataInizioVendita).format();
      const newDateInizio = moment(dateInizioVenditaOnly)
        .add(values.orarioInizioVendita)
        .format();

      const dateFineVenditaOnly = moment(values.dataFineVendita).format();
      const newDateFine = moment(dateFineVenditaOnly)
        .add(values.orarioFineVendita)
        .format();

      const data = {
        dataInizioVendita: newDateInizio,
        dataFineVendita: newDateFine,
        dataFineVenditaReale: newDateFine,
        timer: values.timer,
        rilancioMinimo: values.rilancioMinimo,
        miglioreOfferta: String(accounting.unformat(miglioreOfferta.value)),
        miglioreOfferente: miglioreOfferta.offerente,
        rilanci: [],
        maxRilanci: 0,
      };

      await updateAvvioGara(
        asta.idInserzioneEspVendita,
        data
      );

      toast.success("La gara inizierà tra poco!");
      handleClose();
    }
  };

  useEffect(() => {
    let maxOfferta = 0;
    let offerente = "";
    buste?.forEach((busta) => {
      if (busta.cauzioneVersata && busta.offertaValida) {
        const newFormatOfferta = accounting.unformat(
          busta.offertaRequest.offIntegrale.offerta?.importoOfferta,
          ","
        );

        if (newFormatOfferta > maxOfferta) {
          maxOfferta = newFormatOfferta;
          offerente = busta.credenziali.username;
        }
      }
    });

    setMiglioreOfferta({
      value: accounting.formatMoney(maxOfferta, "€"),
      offerente: offerente,
    });
  }, [buste]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className="modal_termina_esame">
        <div className="modal_termina_esame_title ">
          TERMINA ESAME OFFERTE E AVVIO GARA TELEMATICA
        </div>
        <Box>
          D.M 32/2015 - Art. 22 co.3: i dati contenuti nelle offerte cartacee,
          nonchè i rilanci e le osservazioni dei partecipanti in sala, devono
          essere inseriti nel portale del gestore della gara telematica.
          <br /> Nel caso in cui non siano pervenuto offerte telematiche e non
          venga inserita nessuna offerta cartacea, la gara risulterà
          automaticamente deserta.
        </Box>

        <Box my={4}>La migliore offerta è di : {miglioreOfferta.value} </Box>

        <Box my={2} fontWeight={600}>
          PARAMETRI DA INSERIRE
        </Box>
        <div> Inserire i dati della gara telematica.</div>

        <form onSubmit={formik.handleSubmit}>
          <Grid container paddingTop={2} spacing={3}>
            <Grid item xs={12} md={6}>
              <NumberFormat
                value={formik.values.rilancioMinimo}
                name={"rilancioMinimo"}
                customInput={TextField}
                type="text"
                prefix={"€"}
                thousandSeparator={true}
                label="Rilancio Minimo"
                onValueChange={({ value: v }) =>
                  formik.setFieldValue("rilancioMinimo", v)
                }
                error={
                  formik.touched.rilancioMinimo &&
                  Boolean(formik.errors.rilancioMinimo)
                }
                helperText={
                  formik.touched.rilancioMinimo && formik.errors.rilancioMinimo
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                label="Timer (Minuti)"
                name="timer"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max:
                      asta.datiVendita.modalitaVendita ===
                      "ASINCRONA TELEMATICA"
                        ? 15
                        : 3,
                  },
                }}
                value={formik.values.timer}
                disabled={
                  asta.datiVendita.modalitaVendita ===
                  "ASINCRONA TELEMATICA"
                    ? true
                    : false
                }
                onChange={formik.handleChange}
                helperText={formik.touched.timer && formik.errors.timer}
                error={formik.touched.timer && Boolean(formik.errors.timer)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <DatePicker
                  label="Data Inizio Vendita"
                  inputFormat="dd/MM/yyyy"
                  name="dataInizioVendita"
                  toolbarPlaceholder="Inserisci data Inizio"
                  id="dataInizioVendita"
                  value={formik.values.dataInizioVendita}
                  onChange={(val) =>
                    formik.setFieldValue("dataInizioVendita", val)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formik.touched.dataInizioVendita &&
                        Boolean(formik.errors.dataInizioVendita)
                      }
                      helperText={
                        formik.touched.dataInizioVendita &&
                        formik.errors.dataInizioVendita
                      }
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField
                  type="time"
                  label="Orario Inizio Vendita"
                  name="orarioInizioVendita"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.orarioInizioVendita}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.orarioInizioVendita &&
                    formik.errors.orarioInizioVendita
                  }
                  error={
                    formik.touched.orarioInizioVendita &&
                    Boolean(formik.errors.orarioInizioVendita)
                  }
                  fullWidth
                />
              </FormGroup>
            </Grid>

            {asta.datiVendita.modalitaVendita ===
              "ASINCRONA TELEMATICA" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <DatePicker
                      label="Data Fine Vendita"
                      inputFormat="dd/MM/yyyy"
                      name="dataFineVendita"
                      toolbarPlaceholder="Inserisci data Fine"
                      id="dataFineVendita"
                      value={formik.values.dataFineVendita}
                      onChange={(val) =>
                        formik.setFieldValue("dataFineVendita", val)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.dataFineVendita &&
                            Boolean(formik.errors.dataFineVendita)
                          }
                          helperText={
                            formik.touched.dataFineVendita &&
                            formik.errors.dataFineVendita
                          }
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <TextField
                      type="time"
                      label="Orario Fine Vendita"
                      name="orarioFineVendita"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.orarioFineVendita}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.orarioFineVendita &&
                        formik.errors.orarioFineVendita
                      }
                      error={
                        formik.touched.orarioFineVendita &&
                        Boolean(formik.errors.orarioFineVendita)
                      }
                      fullWidth
                    />
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
          <Button
            variant="contained"
            type="submit"
            style={{ marginTop: "1rem", float: "right" }}
          >
            {" "}
            Conferma i dati e avvio gara
          </Button>
        </form>
      </Box>
    </Modal>
  );
});

export default ModalTerminaEsameOfferte;
