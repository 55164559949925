import React from "react";

import { memo } from "react";
import { useNavigate } from "react-router-dom";
import img_default from "../../assets/default_asta.png";

//utils
import "../../pages/Homepage/Homepage.css";
import { convertDateInIta } from "../../utils/Utils";

//Procedura Giudiziaria

const CardInserzione = memo(({ inserzione }) => {


  const navigate = useNavigate();

  const { lotto } = inserzione;
  const { datiVendita } = inserzione;
  const { proceduraGiudiziaria } = inserzione.datiProcedura;

  const goToAsta = () => {
    navigate(`/asta/${inserzione.idInserzioneEspVendita}`);
  };

  return (
    <React.Fragment>
      <div className="card_container" onClick={goToAsta}>
        <img
          src={img_default}
          alt="img_default"
          className="card_image_section"
        />
        <div className="card_text_section">

          <div className="card_info">
            Id Asta: {inserzione.idInserzioneEspVendita}
          </div>
          
          <div className="card_text_ubicazione">
            {lotto.ubicazione && inserzione?.lotto?.ubicazione?.indirizzo}
          </div>
          
          <div className="card_text_descrizione">
            {lotto.descrizioneIT && lotto?.descrizioneIT.substring(0, 150) + "..."}
          </div>

          <div className="card_base_asta">
            <p>{`${datiVendita.prezzoValoreBase} €`}</p>
          </div>
          <div className="card_info">
            <p>DATA ASTA</p>
            <div>{convertDateInIta(datiVendita.dataOraVendita)}</div>
          </div>
          <div className="card_info">
            <p>CATEGORIA</p>
            <div>{lotto.categoria && lotto.categoria}</div>
          </div>

          <div className="card_info">
            <p>{proceduraGiudiziaria && proceduraGiudiziaria.tribunale}</p>
            <div>{inserzione.tipologiaInserzione}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default CardInserzione;
