
/*

Il contesto useAsta in React gestisce lo stato e le informazioni relative a un'asta specifica. Ecco le principali funzionalità e dati memorizzati:

Stato dell'Asta (asta): Contiene dettagli come siti, referente, datiProcedura, lotto, datiVendita, statoAsta, e altri. Questi dati rappresentano le varie caratteristiche e parametri dell'asta corrente.

Aula Virtuale (aulaVirtuale): Gestisce lo stato di apertura dell'aula virtuale per l'asta, indicando se è attiva o meno.

Buste (buste): Mantiene un elenco delle offerte (buste) relative all'asta, utilizzate per la verifica e l'autenticazione dei partecipanti.

Timer (onTimeLeft): Gestisce un timer o un conto alla rovescia, probabilmente utilizzato per tracciare il tempo rimanente fino alla conclusione dell'asta o fino a un evento importante.

Questo contesto fornisce un meccanismo centralizzato per accedere e modificare lo stato dell'asta all'interno dell'applicazione, rendendo più semplice la gestione delle informazioni relative all'asta in diversi componenti.

*/


import { createContext, useState } from "react";
import { useContext } from "react";

const AstaContext = createContext();

export const AstaContextProvider = ({ children }) => {

  const initialAstaState = {
    siti: [],
    referente: {},
    dataFineVendita: null,
    datiProcedura: {},
    tipologiaInserzione: "",
    lotto: {},
    dataRicezione: {},
    datiVendita: {},
    giorniPubblicita: null,
    messageId: "",
    dataPubblicazione: "",
    statoAsta: "",
    idInserzioneEspVendita: "",
    eventi: null
  }

  const [asta, setAsta] = useState(initialAstaState);
  const [aulaVirtuale,setAulaVirtuale] = useState(false)
  const [buste,setBuste]=useState([])
  const [tempoRimanente,setTempoRimanente] = useState(null)


  

  return (
    <AstaContext.Provider value={{tempoRimanente,setTempoRimanente, asta, setAsta,aulaVirtuale,setAulaVirtuale,buste,setBuste }}>
      {children}
    </AstaContext.Provider>
  );
};

export const useAsta = () => useContext(AstaContext);
