/*
Il componente TitleAsta visualizza informazioni chiave relative a un'asta specifica. 
Utilizza dati forniti dall'hook useAsta, come dettagli della procedura giudiziaria e informazioni sul lotto. 
Se presenti, mostra il numero e l'anno della procedura giudiziaria. 
Per il lotto, visualizza il codice e una descrizione. 
Se la descrizione del lotto supera i 300 caratteri, viene implementata una funzionalità "leggi di più" o "riduci" 
per espandere o comprimere il testo.
*/

import React, { useState } from "react";
import { useAsta } from "../../context/useAsta";

const TitleAsta = () => {
  const { asta } = useAsta();
  const [readMore, setReadMore] = useState(false);

  // console.log( 'ASTA ---> ' + JSON.stringify(asta))

  if (asta) {
    const { inserzioneEspVendita } = asta;
    const { datiProcedura } = asta;
    const { lotto } = asta;

  
    return (
      <React.Fragment>
        <div className="title_container">
        
          <div className="title_procedura">
              {`ID: ${asta.idInserzioneEspVendita} `}
          </div>

          {
          datiProcedura.proceduraGiudiziaria && 
          <div className="title_procedura">
            {`PROCEDURA N.${datiProcedura?.proceduraGiudiziaria?.numeroProcedura}/${datiProcedura?.proceduraGiudiziaria?.annoProcedura} `}
          </div>
          }

          <div className="title_subdescription">
            {lotto.codice && 
            <div>
              CODICE LOTTO: <span>{lotto.codice}</span>
            </div>
            }
            
            {lotto.descrizioneIT && lotto.descrizioneIT.length > 300 ? (
              readMore ? (
                <p className="title_description">
                  {lotto.descrizioneIT}
                  <span onClick={() => setReadMore(!readMore)}>Riduci</span>
                </p>
              ) : (
                <p className="title_description">
                  {lotto.descrizioneIT.substring(0, 300) + "..."}
                  <span onClick={() => setReadMore(!readMore)}>Visualizza</span>
                </p>
              )
            ) : (
              <div style={{width:"100% !important"}}>
                { lotto.descrizioneIT}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

export default TitleAsta;
