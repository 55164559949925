import React, { useState, useEffect } from "react";
import { getTimer, pauseAsta, riavviaAsta } from '../services/timerServices'; // Assicurati che il percorso sia corretto

import { useAuth } from '../context/useAuth'

import { useAsta } from "../context/useAsta";

const dhm = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return `${days}d:${hours}h:${minutes}m:${sec}s`;
};

const CountDown = () => {
  const { isReferente } = useAuth()
  const { asta, tempoRimanente, setTempoRimanente } = useAsta()

  useEffect(() => {
    let timer;
   
    timer = setInterval(async () => {
      try {
        const data = await getTimer(asta.idInserzioneEspVendita);
        setTempoRimanente(data.timer);
        // console.log("Tempo rimanente: " + tempoRimanente)

      } catch (error) {
        console.log(error);
      }
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);

 

  return (
    <>
    {/** @todo gestire il pulsante delle pause */}
{/*         {isReferente &&
      <button onClick={async () => {
            setIsActive(!isActive)
            isActive ?  await pauseAsta(asta.idInserzioneEspVendita): await riavviaAsta(asta.idInserzioneEspVendita)    
        }}>
        {isActive ? 'Disattiva' : 'Attiva'}
      </button>} */}
      {tempoRimanente !== 0 && <div style={timerStyle}>{dhm(tempoRimanente)}</div>}
      {tempoRimanente <= 0 && <div style={timerStyle}>Gara terminata</div>}
    </>
  );
};

const timerStyle = {
  fontSize: '2rem',  // Aumenta la dimensione del font
  fontWeight: 'bold', // Puoi anche renderlo più spesso
  color: '#FF6347',  // Colore arancione chiaro
};


export default CountDown;

