import { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { auth } from "../services/firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReferente, setIsReferente] = useState(false)

  useEffect(() => {
    setLoading(true);
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }

      setLoading(false);
    });
  }, []);

  const loginHandler = async (email, password) => {
    try {
    const userCredential = await auth.signInWithEmailAndPassword(email, password);
    const {emailVerified} = userCredential.user
       return {
        error: false,
        emailVerified:emailVerified
      };
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  };

  const recoverPassHandler = async (email, password) => {
    try {
      await auth.sendPasswordResetEmail(email);
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  };

  const logoutHandler = async () => {
    try {
      await auth.signOut();
      window.location.href = process.env.REACT_APP_HOME_URL;
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  };

  const registerUserHandler = async (email, password) => {
    try {
      await auth.createUserWithEmailAndPassword(email, password);
      await auth.currentUser.sendEmailVerification();
      return {
        error: false,
      };
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        setLoading,
        loginHandler,
        recoverPassHandler,
        logoutHandler,
        registerUserHandler,
        
        isReferente, 
        setIsReferente
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
