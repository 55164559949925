/*

Il componente PartecipantePanel è progettato per l'interfaccia del partecipante in un'asta. 
Gestisce la visualizzazione del pannello del partecipante, inclusi i dettagli come 
lo stato della cauzione versata e la validità dell'offerta. 
Include anche componenti come GaraPanel per la partecipazione attiva nell'asta, TableDocumentiAnonimi 
per visualizzare documenti anonimi, e CronologiaGara per vedere la cronologia degli eventi dell'asta. 
C'è anche una sezione per la chat, disponibile solo in certe modalità di vendita. 
Il componente mostra informazioni pertinenti a un partecipante autenticato, consentendo di monitorare e 
partecipare attivamente all'asta.

*/


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//hooks
import { useAsta } from "../../../context/useAsta";

//components
import TableDocumentiAnonimi from "./TableDocumentiAnonimi";
import CronologiaGara from "./CronologiaGara";
import GaraPanel from "./GaraPanel";
import Chat from "../ReferentePanel/Chat";

//utils
import "./PartecipantePanel.css";
import { db } from "../../../services/firebase";
import { STATI_ASTA } from "../../../utils/Utils";

const PartecipantePanel = () => {
  const { asta, aulaVirtuale, buste } = useAsta();
  const params = useParams();
  const [bustaPartecipante, setBustaPartecipante] = useState(null);

  useEffect(() => {
    db.collection("Offerte")
      .doc(aulaVirtuale.idOfferta)
      .onSnapshot((docRef) => setBustaPartecipante(docRef.data()));
  }, [aulaVirtuale.idOfferta]);

  if (
    aulaVirtuale &&
    aulaVirtuale.type === "partecipante" &&
    aulaVirtuale.messageId === params.id
  ) {
    return (
      <div>
        {asta.aulaVirtuale ? (
          <>
            {STATI_ASTA.IN_CORSO === asta.statoAsta &&
              bustaPartecipante?.cauzioneVersata &&
              bustaPartecipante?.offertaValida && <GaraPanel />}

            <div className="partecipante_container">
              <div className="gara_box_chat">
              
              {/** @todo La chat non deve comparire in caso di asta ASINCRONA TELEMATICA */}
{/*               {asta.datiVendita.modalitaVendita !== "ASINCRONA TELEMATICA" &&  
              <>
                <div> {asta.datiVendita.modalitaVendita}</div>
                <Chat />
              </>
              } */}


              </div>

              <div className="partecipante_title"> PANNELLO PARTECIPANTE</div>
              <div>
                Per questa vendita il tuo pseudonimo è :
                <div style={{ display: "inline", fontWeight: 600 }}>
                  {" "}
                  {aulaVirtuale.nomeUtente}
                </div>
                <div className="partecipante_cauzione">
                  {" "}
                  Stato Cauzione (Versata/Non Versata) :{" "}
                  {bustaPartecipante?.cauzioneVersata === null ? (
                    "Verifica in corso..."
                  ) : bustaPartecipante?.cauzioneVersata ? (
                    <div
                      style={{
                        color: "green",
                        display: "inline",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Cauzione versata{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "red",
                        display: "inline",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Cauzione non versata{" "}
                    </div>
                  )}
                </div>
                <div className="partecipante_offerta">
                  Stato Offerta (Valida /Non Valida) :{" "}
                  {bustaPartecipante?.offertaValida === null ? (
                    "Verifica in corso..."
                  ) : bustaPartecipante?.offertaValida ? (
                    <div
                      style={{
                        color: "green",
                        display: "inline",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Offerta valida{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "red",
                        display: "inline",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      Offerta non valida{" "}
                    </div>
                  )}
                </div>
              </div>

              <TableDocumentiAnonimi />
              <CronologiaGara />
            </div>
          </>
        ) : (
          <div className="partecipante_container">
            <span>AULA VIRTUALE CHIUSA</span>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default PartecipantePanel;
