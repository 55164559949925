/*

Il flusso di base del componente AulaVirtuale può essere riassunto nei seguenti passaggi:

- Caricamento e Inizializzazione: All'avvio, il componente carica le buste (offerte) dell'asta e inizializza gli stati, 
  inclusa la visibilità della password (le buste sono presenti nel caso di Asta senza incanto - //@todo, da verificare).

- Form di Autenticazione: Presenta un form dove gli utenti inseriscono il loro nome utente e la password.

- Verifica delle Credenziali: Quando l'utente sottomette il form, il componente verifica le credenziali. 
Prima controlla se l'utente è il referente dell'asta, e poi scorre le buste per identificare l'utente come un partecipante.
//@todo e gli spettatori? Possono entrare?

- Impostazione dell'Aula Virtuale e Feedback: Se le credenziali sono corrette, imposta i dettagli dell'utente nell'aula virtuale e 
mostra un messaggio di benvenuto. In caso contrario, mostra un messaggio di errore.

- Opzioni Aggiuntive: Se l'utente non è autenticato, offre la possibilità di navigare alla pagina di login.

*/


import React, { memo, useState, useEffect, useCallback } from "react";


// Importazioni di Material UI e icone
import {
  Button,
  TextField,
  IconButton,
  Grid,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//hooks
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAsta } from "../../context/useAsta";
import { useAuth } from "../../context/useAuth";
import { useBreakPoints } from "../../utils/useBreakPoints";
import { useParams } from "react-router-dom";

//utils
import * as Yup from "yup";
import "../../pages/Asta/Asta.css";
import { toast } from "react-toastify";
import { getBusteAsta } from "../../services/firebase";
import bcrypt from "bcryptjs";





const AulaVirtuale = memo(() => {
  
  // Stato per mostrare/nascondere la password
  const [showPassword, setShowPassword] = useState(false);

  const { asta, buste, setBuste, setAulaVirtuale } = useAsta();
  const { user, isReferente, setIsReferente } = useAuth();

  const { sm } = useBreakPoints();
  const navigate = useNavigate();
  const params = useParams();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  // Carica le buste dell'asta al caricamento del componente
  useEffect(() => {
    getBusteAsta(asta.idInserzioneEspVendita).then(
      (data) => {
        setBuste(data);
      }
    );
  }, [asta.idInserzioneEspVendita, setBuste]);

  const initialValue = {
    nomeUtente: "",
    password: "",
  };

  // Schema di validazione del form
  const validationSchema = Yup.object({
    nomeUtente: Yup.string().required("Inserisci il nome utente"),
    password: Yup.string().required("Inserisci password"),
  });

  // Gestione del submit del form
  const submitHandler = async (value) => {

    // Logica per verificare le credenziali del referente e impostare l'aula virtuale
    const { referente } = asta;
    const { nomeUtente, password } = value;

    const searchReferente = 
      referente.codiceFiscale === nomeUtente &&
      (await bcrypt.compare(password, referente.password));

    if (searchReferente) 
        setIsReferente(true)
    else 
        setIsReferente(false)
   

    let trovato = false;

    if (searchReferente) {
      const data = {
        messageId: params.id,
        nomeUtente: nomeUtente,
        type: "referente",
      };

      setAulaVirtuale(data);
      toast.success("Benvenuto nell'area virtuale");
    } else {


      /* Promise.all per eseguire operazioni asincrone in parallelo su ogni elemento dell'array buste. 
      Ogni busta rappresenta un'offerta fatta in un'asta.*/

      // Esegue un'operazione asincrona su ogni 'busta' nell'array 'buste'
      await Promise.all(buste.map(async (busta) => {

          // Confronta il nome utente e verifica la password criptata
          const searchPartecipante =
            nomeUtente === busta.credenziali.username &&
            (await bcrypt.compare(password, busta.credenziali.password));

          // Costruisce il nome reale del partecipante combinando nome e cognome
          const nomeReale = `${busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.nome} ${busta.offertaRequest.offIntegrale.presentatore.datiAnagrafici.cognome}`;

          // Se le credenziali corrispondono, imposta i dati per l'aula virtuale
          if (searchPartecipante) {
            trovato = true;

            // Prepara i dati da impostare per l'aula virtuale
            const data = {
              messageId: params.id,
              nomeReale,
              nomeUtente: nomeUtente,
              type: "partecipante",
              idOfferta: busta.idRef,
            };

            // Imposta l'aula virtuale con i dati del partecipante
            setAulaVirtuale(data);

            // Mostra un messaggio di successo all'utente
            toast.success("Benvenuto nell'area virtuale");
          }
        })
      );

      // messaaggio di errore se non viene trovata corrispondenza con un referente
      if (!trovato) toast.error("Password o email errata");
    }
  };

  const goToLogin = useCallback(() => {
    toast.warn("Devi essere prima registrato al portale");
    navigate("/login");
  }, [navigate]);


  // Inizializzazione del form con Formik
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });


  // Rendering del componente
  return (
    <div className="aula_virtuale_container">
      <div className="aula_virtuale_title">ACCESSO AULA VIRTUALE</div>
      <div className="aula_virtuale_subtitle">
      Gentile utente, inserisci qui le credenziali univoche ricevute mezzo mail e/o cellulare per poter accedere all’aula virtuale della gara
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item md={6} xs={12}>
            <TextField
              name="nomeUtente"
              type="string"
              onChange={formik.handleChange}
              value={formik.values.nomeUtente}
              label="Nome Utente"
              error={
                formik.touched.nomeUtente && Boolean(formik.errors.nomeUtente)
              }
              helperText={formik.touched.nomeUtente && formik.errors.nomeUtente}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.password}
              label="Inserisci Password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <div className="aula_virtuale_button">
          {user ? (
            <Button variant="contained" type="submit" fullWidth={!sm}>
              Accedi
            </Button>
          ) : (
            <Button onClick={goToLogin} fullWidth={!sm} variant="contained">
              Accedi
            </Button>
          )}
        </div>
      </form>
    </div>
  );
});

export default AulaVirtuale;
