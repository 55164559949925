
/*

La pagina Asta in React è progettata per fornire una vista dettagliata di un'asta specifica, 
combinando diversi componenti che mostrano varie informazioni relative all'asta. 
Include componenti come InfoAsta, TitleAsta, DatiPvp, IscrizioneVendita, AulaVirtuale, 
Ubicazione, ReferentePanel, PartecipantePanel, PhotoGallery, e EventiAsta. 
Questi componenti mostrano titoli, foto, eventi, informazioni sulla vendita e sull'ubicazione, 
dati del referente e del partecipante, e consentono l'iscrizione alla vendita. 
Il contenuto specifico visualizzato dipende dallo stato corrente dell'asta, come definito nel contesto useAsta.

*/

import React, { useEffect } from "react";

//components
import InfoAsta from "../../components/Asta/InfoAsta";
import TitleAsta from "../../components/Asta/TitleAsta";
import DatiPvp from "../../components/Asta/DatiPvp";
import IscrizioneVendita from "../../components/Asta/IscrizioneVendita";
/* import PrenotaVisita from "../../components/Asta/PrenotaVisita";
import AssistiGara from "../../components/Asta/AssistiGara"; */
import AulaVirtuale from "../../components/Asta/AulaVirtuale";
import Ubicazione from "../../components/Asta/Ubicazione";
import ReferentePanel from "../../components/Asta/ReferentePanel/ReferentePanel";
import DatiPrincipali from "../../components/Asta/DatiPrincipali";
import PartecipantePanel from "../../components/Asta/PartecipantePanel/PartecipantePanel";
import PhotoGallery from "../../components/Asta/PhotoGallery";
import EventiAsta from "../../components/Asta/EventiAsta";
import AllegatiAsta from "../../components/Asta/Allegati";

//context
import { useAuth } from "../../context/useAuth";
import { useAsta } from "../../context/useAsta";

//hook
import { useParams } from "react-router-dom";

//db
import { db } from "../../services/firebase";
import { Grid } from "@mui/material";

//utils
import "./Asta.css";
import { Helmet } from "react-helmet-async";

const STATI_ASTA = {
  AVVISO: "AVVISO",
  ISCRIZIONI_IN_CORSO: "ISCRIZIONI_IN_CORSO",
  IN_ATTESA_DI_AVVIO: "IN_ATTESA_DI_AVVIO",
  IN_CORSO: "IN_CORSO",
  SOSPESA: " SOSPESA",
  IN_PAUSA: "IN_PAUSA",
  TERMINATA_NO_INVIO: "TERMINATA_NO_INVIO",
  TERMINATA_OK_INVIO: "TERMINATA_OK_INVIO",
};

const Asta = () => {
  // Ottiene l'ID dell'asta dall'URL
  const params = useParams();

  // Hook per gestire lo stato di caricamento dell'applicazione
  const { setLoading, isReferente } = useAuth();

  // Hook per accedere e impostare lo stato dell'asta corrente e l'aula virtuale
  const { asta, setAsta, aulaVirtuale } = useAsta();

  // useEffect per caricare i dati dell'asta
  useEffect(() => {

    // Indica l'inizio del caricamento
    setLoading(true);

    // Interroga il database Firebase per ottenere i dettagli dell'asta
    db.collection("Inserzioni")
      .doc(params.id)
      .onSnapshot((docRef) => {
        // Imposta i dati dell'asta nello stato
        setAsta(docRef.data());
      });

    // Reset dello stato di caricamento
    setLoading(false);

    // Funzione di pulizia: resetta lo stato dell'asta quando il componente viene smontato
    return () => {
      setAsta(null);
    };
  }, [setLoading, setAsta, params.id]); // Dipendenze dello useEffect



  if (asta) {
    return (
      <div className="container">
        <Helmet>
        {asta.inserzioneEspVendita &&
          <>
          <title>
            {" "}
            {`ASTALEX | Asta giudiziaria ${asta.idInserzioneEspVendita}`}{" "}
          </title>
            <meta
              name="description"
              content="Acquista su Astalex.it il portale per beni derivanti da vendite giudiziarie e aste fallimentari. 
              Aggiudicati un'asta al miglior prezzo"
            />
          </>
          }
          {asta.inserzioneEspVendita && 
          <link
            rel="canonical"
             href={`asta/${asta.idInserzioneEspVendita}`}
          />}
        </Helmet>

        <Grid container padding={2}>
          <Grid item padding={2} md={12}>
            <TitleAsta />
          </Grid>
          <Grid item padding={2} md={12}>
            <EventiAsta />
          </Grid>

          <Grid item padding={2} md={6} xs={12}>
            <div className="item_grid">
              <PhotoGallery />
            </div>
          </Grid>
          <Grid item padding={2} md={6} xs={12}>
            <div className="item_grid">
              <InfoAsta />
            </div>
          </Grid>

          {/** SE CI SI PUO' ISCRIVERE ALL'ASTA */}
          {asta.statoAsta === STATI_ASTA.ISCRIZIONI_IN_CORSO && (
            <Grid container padding={2}>
              <Grid item padding={2} md={12}>
                <div className="item_grid_tab">
                  <IscrizioneVendita />
                </div>
              </Grid>

              {/*  <Grid item padding={2} md={12}>
                <div className="item_grid_tab">
                  <PrenotaVisita />
                </div>
              </Grid>

              <Grid item padding={2} md={12}>
                <div className="item_grid_tab">
                  <AssistiGara />
                </div>
              </Grid> */}
            </Grid>
          )}

          <Grid item padding={2} xs={12} md={12}>
            <div className="item_grid_tab">
              <AllegatiAsta />
            </div>
          </Grid>


          {/** ASTA  IN ATTESA DI AVVIO  **/}

{/*           
L'aula virtuale sembra essere visibile e accessibile in determinati stati dell'asta, in particolare quando l'asta è:
In Attesa di Avvio: Prima che l'asta inizi ufficialmente, l'aula virtuale può essere utilizzata per preparativi o informazioni preliminari.
In Corso: Durante lo svolgimento dell'asta, l'aula virtuale è probabilmente il luogo principale per la partecipazione attiva all'asta.
Terminata con Invio (OK_INVIO): Anche dopo la conclusione dell'asta, l'aula virtuale potrebbe rimanere accessibile per post-vendita o comunicazioni finali.
*/}

          {
          
          
          // (asta.statoAsta === STATI_ASTA.IN_ATTESA_DI_AVVIO || isReferente ||
          // asta.statoAsta === STATI_ASTA.IN_CORSO || asta.statoAsta === STATI_ASTA.TERMINATA_OK_INVIO) &&
            !(
              asta.statoAsta === STATI_ASTA.AVVISO || 
              asta.statoAsta === STATI_ASTA.ISCRIZIONI_IN_CORSO ||
              aulaVirtuale.messageId === params.id
            ) 
            && 
            asta.aulaVirtuale && (
              <Grid item padding={2} md={12}>
                <div className="item_grid_tab">
                  <AulaVirtuale />
                </div>
              </Grid>
            )}





          <Grid item padding={2} xs={12} md={12}>
            <div className="item_grid_tab">
              <ReferentePanel />
            </div>
          </Grid>

          <Grid item padding={2} xs={12} md={12}>
            <div className="item_grid_tab">
              <PartecipantePanel />
            </div>
          </Grid>

          <Grid item padding={2} md={6} xs={12}>
            <div className="item_grid_information">
              <DatiPrincipali />
            </div>
          </Grid>
          {asta.inserzioneEspVendita?.lotto?.ubicazione && (
            <Grid item padding={2} md={6} xs={12}>
              <div className="item_grid_information">
                <Ubicazione />
              </div>
            </Grid>
          )}
          <Grid item padding={2} xs={12}>
            <div className="item_grid_tab">
              <DatiPvp />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return null;
};

export default Asta;
